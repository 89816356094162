/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.flatpickr-months .flatpickr-month,.flatpickr-current-month .flatpickr-monthDropdown-months,span.flatpickr-weekday,.flatpickr-weekdays {
  background: #6236FF!important;

}
span.flatpickr-weekday{
  color: #fff!important;
}