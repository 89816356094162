.my-qrcode{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
}
.username{
    color: rgb(49, 49, 255);
    margin-top:10px;
}

.btn {
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 400;
    text-shadow: none;
    color: #fff;
    background-color: #6736ff;
    border-radius:8px;
}
